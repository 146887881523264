<template>
  <div class="">
    <div class="" v-if="!toggle">
      <span class="">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>

        <div class="">
          <fa
            v-if="toggle"
            icon="trash"
            class="text-danger mr-1"
            @click="destroyData(item.id)">
          </fa>
          <span>
            {{idx+1}}. {{item.account.code}} ({{item.account.name}})
          </span>
        </div>
      </span>
    </div>

    <div class="" v-if="toggle">
      <div class="">
        <div class="form-row">
          <DetailAccountInput
            label="บัญชี"
            class="col-12 col-sm-6"
            :procedureType="procedureType"
            :templateType="templateType"
            v-model="formData.accountId"
            select="id">
          </DetailAccountInput>
        </div>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="createData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import round from 'lodash/round'
import DetailAccountInput from './DetailAccountInput'
import {
  CREATE_GROUP_ITEM,
  UPDATE_GROUP_ITEM,
  DESTROY_GROUP_ITEM
} from './graph'

export default {
  props: {
    groupType: {
      type: String,
      required: true
    },
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    idx: {
      type: Number,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    },
    preQty: {
      type: Number,
      required: false,
      default: 0
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        accountId: null
      }
    }
  },
  computed: {
    preMinQty () {
      return this.calculateQty(this.preQty, this.item.minQty)
    },
    preMaxQty () {
      return this.calculateQty(this.preQty, this.item.maxQty)
    }
  },
  validations: {
    formData: {
      accountId: {required},
    }
  },
  methods: {
    serializeInput (formData) {
      return formData
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_GROUP_ITEM(this.templateType),
        variables: {
          procedureType: this.procedureType,
          groupType: this.groupType,
          parentId: this.parentId,
          input: this.serializeInput(this.formData)
        }
      })
      .then(res => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$emit('created', res.data.createGroupItem)
        this.toggle = false
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_GROUP_ITEM(this.templateType),
        variables: {
          procedureType: this.procedureType,
          groupType: this.groupType,
          groupItemId: this.item.id,
          input
        }
      })
      .then(res => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.$emit('updated', res.data.updateGroupItem)
        this.toggle = false
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_GROUP_ITEM(this.templateType),
        variables: {
          procedureType: this.procedureType,
          groupType: this.groupType,
          groupItemId: this.item.id
        }
      })
      .then(res => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.$emit('destroyed', res.data.destroyGroupItem)
        this.toggle = false
      })
      .catch(this.$toasted.global.error)
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    },
    calculateQty (actQty, refQty='') {
      const isPercent = '%' === refQty.charAt(refQty.length-1)
      let qty = 0

      if (isPercent) {
        const multi = (Number(refQty.substring(0, refQty.length-1)) / 100)
        qty = round(actQty * multi, 2)
      } else {
        qty = round(Number(refQty), 2)
      }

      if (isNaN(qty)) return 'NOT_VALID'
      else return qty
    },
    clearFormData () {
      this.formData.accountId = null
    },
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.formData.accountId = this.item.accountId
        })
      } else {
        this.clearFormData()
      }
    }
  },
  created () {
    if (this.isForm) this.toggle = true
  },
  components: {
    DetailAccountInput,
  }
}
</script>

<style lang="css" scoped>
</style>
