<template>
  <div class="card">
    <div class="card-body">
      <span class="card-text">
        <div class="">
          <span class="text-warning h6">{{item.name}}</span>
          <span
            class="float-right px-2 pointer text-success"
            @click="addCardForm">
            <fa icon="plus"></fa>
          </span>
        </div>

        <DetailGroupMaterialCard
          class="mt-3"
          v-for="(card, idx) in item.children"
          :idx="idx"
          :key="card.id"
          :item="card"
          :parentId="item.id"
          :preQty="preQty"
          :procedureType="procedureType"
          :templateType="templateType"
          :groupType="groupType"
          @updated="cardUpdated"
          @destroyed="cardUpdated">
        </DetailGroupMaterialCard>

        <DetailGroupMaterialCard
          key="form"
          class="mt-3"
          v-if="isCardAdd"
          :idx="0"
          :isForm="isCardForm"
          :item="cardData"
          :parentId="item.id"
          :preQty="preQty"
          :procedureType="procedureType"
          :templateType="templateType"
          :groupType="groupType"
          @created="cardUpdated"
          @cancel="cancelCardForm">
        </DetailGroupMaterialCard>
      </span>
    </div>
  </div>
</template>

<script>
import DetailGroupMaterialCard from './DetailGroupMaterialCard'

export default {
  props: {
    groupType: {
      type: String,
      required: true
    },
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    preQty: {
      type: Number,
      required: false,
      default: 0
    },
  },
  data () {
    return {
      isCardAdd: false,
      isCardForm: false,
      cardData: {},
    }
  },
  methods: {
    addCardForm () {
      if (this.isCardForm) return

      this.isCardAdd = true
      this.cardData = {}
      this.isCardForm = true
    },
    cancelCardForm () {
      this.isCardAdd = false
      this.cardData = {}
      this.isCardForm = false
    },
    cardUpdated (payload) {
      this.cancelCardForm()
      this.$emit('updated', payload)
    }
  },
  components: {
    DetailGroupMaterialCard
  }
}
</script>

<style lang="css" scoped>
</style>
