import gql from 'graphql-tag'


const detailResponse = `
  id type code name order parentId
  standardPrice
  qty minQty maxQty
  inventoryId inventory {
    id type code name
    unitId unit {id name}
  }
  accountId account {id code name}
`

export const CREATE_GROUP_ITEM = (templateType) => gql`mutation CREATE_GROUP_ITEM ($procedureType: String!, $groupType: String!, $parentId: Int!, $input: ${templateType}GroupItemInput!) {
  createItem: create${templateType}GroupItem (procedureType: $procedureType, groupType: $groupType, parentId: $parentId, input: $input) {${detailResponse}}
}`

export const UPDATE_GROUP_ITEM = (templateType) => gql`mutation UPDATE_GROUP_ITEM ($procedureType: String!, $groupType: String!, $groupItemId: Int!, $input: ${templateType}GroupItemInput!) {
  updateItem: update${templateType}GroupItem (procedureType: $procedureType, groupType: $groupType, groupItemId: $groupItemId, input: $input) {${detailResponse}}
}`

export const DESTROY_GROUP_ITEM = (templateType) => gql`mutation DESTROY_GROUP_ITEM ($procedureType: String!, $groupType: String!, $groupItemId: Int!) {
  destroyItem: destroy${templateType}GroupItem (procedureType: $procedureType, groupType: $groupType, groupItemId: $groupItemId) {id}
}`
