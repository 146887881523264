import gql from 'graphql-tag'


const detailResponse = `
  id type code name isActive
  categories {id name}
  children {
    id type code name order parentId
    children {
      id type code name order parentId
      standardPrice
      qty minQty maxQty
      inventoryId inventory {
        id type code name
        unitId unit {id name}
      }
      accountId account {id code name}
    }
  }
`

export const LIST_PROCEDURE_INVENTORY = (templateType) => gql`query LIST_PROCEDURE_INVENTORY ($procedureType: String!, $q: FilterInput) {
  procedureInventories: list${templateType} (procedureType: $procedureType, q: $q) {
    id type code name isActive
  }
}`

export const DETAIL_PROCEDURE_INVENTORY = (templateType) => gql`query DETAIL_PROCEDURE_INVENTORY ($procedureType: String!, $procedureInventoryId: Int!) {
  procedureInventory: detail${templateType} (procedureType: $procedureType, procedureInventoryId: $procedureInventoryId) {${detailResponse}}
}`

export const CREATE_PROCEDURE_INVENTORY = (templateType) => gql`mutation CREATE_PROCEDURE_INVENTORY ($procedureType: String!, $input: ${templateType}Input!) {
  createProcedureInventory: create${templateType} (procedureType: $procedureType, input: $input) {${detailResponse}}
}`

export const UPDATE_PROCEDURE_INVENTORY = (templateType) => gql`mutation UPDATE_PROCEDURE_INVENTORY ($procedureType: String!, $procedureInventoryId: Int!, $input: ${templateType}Input!) {
  updateProcedureInventory: update${templateType} (procedureType: $procedureType, procedureInventoryId: $procedureInventoryId, input: $input) {${detailResponse}}
}`

export const DESTROY_PROCEDURE_INVENTORY = (templateType) => gql`mutation DESTROY_PROCEDURE_INVENTORY ($procedureType: String!, $procedureInventoryId: Int!) {
  destroyProcedureInventory: destroy${templateType} (procedureType: $procedureType, procedureInventoryId: $procedureInventoryId) {id}
}`
