import gql from 'graphql-tag'


export const DP_INVENTORIES = (templateType) => gql`query DP_INVENTORIES ($procedureType: String!, $tag: String!, $q: FilterInput) {
  items: list${templateType}DropdownInventory (procedureType: $procedureType, tag: $tag, q: $q) {
    id type code name
  }
}`

export const DP_INVENTORY_TYPES = (templateType) => gql`query DP_INVENTORY_TYPES ($procedureType: String!, $tag: String!) {
  types: list${templateType}DropdownInventoryType (procedureType: $procedureType, tag: $tag) {
    text value
  }
}`

export const DP_ACCOUNTS = (templateType) => gql`query DP_ACCOUNTS ($procedureType: String!, $q: FilterInput) {
  items: list${templateType}DropdownAccount (procedureType: $procedureType, q: $q) {
    id code name
  }
}`
