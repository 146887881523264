import gql from 'graphql-tag'

export const LIST_IMPORT_VALIDATION = (templateType) => gql`query LIST_IMPORT_VALIDATION ($procedureType: String!, $input: [${templateType}ImportInput]!) {
  items: list${templateType}ImportValidation (procedureType: $procedureType, input: $input) {
    tag code name
    children {
      type inventoryCode minQty maxQty
    }
  }
}`

export const CREATE_IMPORT = (templateType) => gql`mutation CREATE_IMPORT ($procedureType: String!, $input: ${templateType}ImportInput!) {
  createProcedureInventory: create${templateType}Import (procedureType: $procedureType, input: $input)
}`
