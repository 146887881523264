<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-sm-6"
        label="รหัส"
        v-model="formData.code"
        :validations="[
          {text: 'required!', value: v.formData.code.$dirty && v.formData.code.$error}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-sm-6"
        label="ชื่อ"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: v.formData.name.$dirty && v.formData.name.$error}
        ]">
      </sgv-input-text>
    </div>

    <CategoryCheck
      :procedureType="procedureType"
      :templateType="templateType"
      :disabled="$auth.disabled(method)"
      v-model="formData.categories">
    </CategoryCheck>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          :disabled="$auth.disabled(method)"
          inline
          label="ใช้งาน"
          v-model="formData.isActive">
        </sgv-input-check>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryCheck from '@/views/procedure_core/CategoryCheck.vue'

export default {
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  components: {
    CategoryCheck
  }
}
</script>

<style lang="css">
</style>
